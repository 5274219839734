import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col} from 'react-bootstrap'
import {SectionWrap} from './section.style'
import SwiperSlider from '../../../../components/ui/swiper'
import ClientLogo from '../../../../components/ui/client-logo'

import BrandImage1 from '../../../../data/images/partners/liv.png'
import BrandImage1Hover from '../../../../data/images/partners/liv.png'
import BrandImage2 from '../../../../data/images/partners/jh.png'
import BrandImage2Hover from '../../../../data/images/partners/jh.png'
import BrandImage3 from '../../../../data/images/partners/bartlets.png'
import BrandImage3Hover from '../../../../data/images/partners/bartlets.png'
import BrandImage4 from '../../../../data/images/partners/humphreys.png'
import BrandImage4Hover from '../../../../data/images/partners/humphreys.png'
import BrandImage5 from '../../../../data/images/partners/northwood.png'
import BrandImage5Hover from '../../../../data/images/partners/northwood.png'
import BrandImage6 from '../../../../data/images/partners/denton.png'
import BrandImage6Hover from '../../../../data/images/partners/denton.png'
import BrandImage7 from '../../../../data/images/partners/beresford.png'
import BrandImage7Hover from '../../../../data/images/partners/beresford.png'
import BrandImage8 from '../../../../data/images/partners/leaders.png'
import BrandImage8Hover from '../../../../data/images/partners/leaders.png'
import BrandImage9 from '../../../../data/images/partners/savills.png'
import BrandImage9Hover from '../../../../data/images/partners/savills.png'
import BrandImage10 from '../../../../data/images/partners/berrys.png'
import BrandImage10Hover from '../../../../data/images/partners/berrys.png'
import BrandImage11 from '../../../../data/images/partners/sourced.png'
import BrandImage11Hover from '../../../../data/images/partners/sourced.png'
import BrandImage12 from '../../../../data/images/partners/nordic.png'
import BrandImage12Hover from '../../../../data/images/partners/nordic.png'
import BrandImage13 from '../../../../data/images/partners/jayne.png'
import BrandImage13Hover from '../../../../data/images/partners/jayne.png'
import BrandImage14 from '../../../../data/images/partners/bookabuilder.png'
import BrandImage14Hover from '../../../../data/images/partners/bookabuilder.png'
import BrandImage15 from '../../../../data/images/partners/prs.png'
import BrandImage15Hover from '../../../../data/images/partners/prs.png'
import BrandImage16 from '../../../../data/images/partners/bpas.png'
import BrandImage16Hover from '../../../../data/images/partners/bpas.png'
import BrandImage17 from '../../../../data/images/partners/rmg.png'
import BrandImage17Hover from '../../../../data/images/partners/rmg.png'
import BrandImage18 from '../../../../data/images/partners/countrywide.png'
import BrandImage18Hover from '../../../../data/images/partners/countrywide.png'

const Section = ({slider, sliderStyle}) => {
    const [sliderImg] = useState([
        {
            img: BrandImage1,
            hoverImg: BrandImage1Hover
        },
        {
            img: BrandImage2,
            hoverImg: BrandImage2Hover
        },
        {
            img: BrandImage3,
            hoverImg: BrandImage3Hover
        },
        {
            img: BrandImage4,
            hoverImg: BrandImage4Hover
        },
        {
            img: BrandImage5,
            hoverImg: BrandImage5Hover
        },
        {
            img: BrandImage6,
            hoverImg: BrandImage6Hover
        },
        {
            img: BrandImage7,
            hoverImg: BrandImage7Hover
        },
        {
            img: BrandImage8,
            hoverImg: BrandImage8Hover
        },
        {
            img: BrandImage9,
            hoverImg: BrandImage9Hover
        },
        {
            img: BrandImage10,
            hoverImg: BrandImage10Hover
        },
        {
            img: BrandImage11,
            hoverImg: BrandImage11Hover
        },
        {
            img: BrandImage12,
            hoverImg: BrandImage12Hover
        },
        {
            img: BrandImage13,
            hoverImg: BrandImage13Hover
        },
        {
            img: BrandImage14,
            hoverImg: BrandImage14Hover
        },
        {
            img: BrandImage15,
            hoverImg: BrandImage15Hover
        },
        {
            img: BrandImage16,
            hoverImg: BrandImage16Hover
        },
        {
            img: BrandImage17,
            hoverImg: BrandImage17Hover
        },
        {
            img: BrandImage18,
            hoverImg: BrandImage18Hover
        }
    ])
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SwiperSlider settings={slider} {...sliderStyle}>
                            {sliderImg.map((slide, i) => {
                                return(
                                    <div key={i} className="item">
                                        <ClientLogo brandImage={slide.img} hoverImage={slide.hoverImg}/>
                                    </div>
                                )
                            })}
                        </SwiperSlider>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

Section.propTypes = {
    slider: PropTypes.object
}

Section.defaultProps = {
    slider: {
        slidesPerView: 6,
        loop: true,
        speed: 1000,
        spaceBetween: 30,
        autoplay: {
            delay: 2500
        },
        breakpoints: {
            320: {
                slidesPerView : 2
            },
            575:{
                slidesPerView : 3
            },
            767:{
                slidesPerView : 4
            },
            991:{
                slidesPerView : 5
            },            
            1499:{
                slidesPerView : 6
            }
        }
    },
    sliderStyle: {
        align: 'center'
    }
}


export default Section;